<template>
    <div class="content" :class="{m_content:$store.state.screenType}">
        <Navbar></Navbar>
        <div class="content_main">
            <h2>{{ list.newsTitle }}</h2>
            <span class="time">{{ $getTime(list.createTime).year }}/{{ $getTime(list.createTime).month }}/{{ $getTime(list.createTime).day }}</span>
            <div class="line-head"></div>
            <div class="content_main_subject" v-html="list.newsContent">

            </div>
        </div>
        <Footer></Footer>
        <top></top>
    </div>
</template>

<script>
import Navbar from '../../components/Nav/Navbar'
import Footer from '../../components/Footer/Footers'


export default {
    name: "Content",
    components: {
        Navbar,
        Footer
    },
    data () {
        return {
            id: -1,
            date:"",
            list: {},//主体数据
            meta: {
                Title: '新闻内容'
            }
        }
    },
    methods: {
        getList () {
            this.$http.getNewContent(this.id * 1).then(res => {
                //console.log(res)
                this.list = res.data.data
                this.meta.Title = res.data.data.newsTitle
                this.$getMedia()
            })
        },
        
    },
    // created () {
    //     this.id = this.$route.query.id
    //     this.getList()

    // },
    activated(){
        this.id = this.$route.query.id
        this.getList()
    }

}
</script>


<style scoped lang="scss">

/*-- -------Content---------- --*/
.content {
    &_main {
        padding: 11.625rem 19.25rem;

        h2 {
            font-size: 3.125rem;
            line-height: 6.25rem;
            //font-weight: normal;
            font-family: 'SourceHanSansCN-Regular';
            color: #1F1F1F;
        }

        .time {
            font-size: 1.875rem;
            line-height: 3.75rem;
            color: #676663;
        }

        .line-head {
            width: 100%;
            height: 0.0625rem;
            background-color: #676663;
            margin: 0.75rem 0 4.5rem 0;
        }

        &_subject {
            font-size: 1.675rem;
            line-height: 3.75rem;
            width: 100%;
            font-family: 'SourceHanSansCN-Light';
            text-align: justify;
            ::v-deep p {
                margin-bottom: 2rem;
                //text-indent: 3.75rem;
                color: #1F1F1F;
            }

            ::v-deep img {
                width: 100%;
                margin-bottom: 2rem;
            }

            ::v-deep iframe {
                margin-left: 50%;
                transform: translate(-50%, 0);
                width: 100%;
                height: 628px;
            }
        }
    }

    .ql-align-center {
        margin: 0 auto;
    }
}

.m_content {
    ::v-deep iframe {
        margin-left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        height: auto !important;
    }
}

@import 'm-content';
</style>
